import BasePage from '@/found/components/table_page';

export default {
  name: 'activity_audit_detail',
  extends: BasePage,
  data() {
    return {

    };
  },
  components: {

  },
  async created() {
    await this.getConfigList('activity_audit_detail');
  },
  methods: {},
};
